import axios from 'axios';

const CancelToken = axios.CancelToken;
console.log('node_env', process.env.NODE_ENV)

export default {
    API_URL: "https://admin-backend-dev.gcpserver.123projetei.com/api",
    API_URL_BUSCADOR: "https://projects-123projetei-api-qa.gcpserver.123projetei.com",
    API_KEY: 'rHxeqAYiBymZSTKa957kDM',
    SOURCE_CANCEL: CancelToken.source()
    // API_URL: `${admin-backend-dev.gcpserver.123projetei.com}/api`,
    // API_URL_BUSCADOR: `${projects-123projetei-api-qa.gcpserver.123projetei.com}`,
    // API_KEY: `${rHxeqAYiBymZSTKa957kDM}`,
    // SOURCE_CANCEL: CancelToken.source()
}



